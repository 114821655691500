.App {
  width: 100%;
  font-family: 'Inter', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, sans-serif !important;
}


/* dropdown (single select) */
#dropdown-basic-button {
  background-color: white;
  color: #58595b;
  border: 1px solid #eaeaea;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
  padding: 2px 8px;
}

.dropdown-menu {
  width: 100%;
  border: 1px solid #eaeaea !important;
  font-size: 14px !important;
  --bs-dropdown-link-active-bg: #eaeaea !important;
  overflow-y: scroll;
  max-height: 300px;
}

.dropdown-menu.show {
  border-radius: 2px;
  width: fit-content;
}

.dropdown-item {
  color: #3e3e3e !important;
  font-size: 13px;
}

.dropdown-for-tableheader > button {
  font-size: 12px !important;
  height: 26px;
}

/* ellipse for long text */
.ellipsed {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


/* multi select dropdown */
.rmsc {
  --rmsc-main: none !important;
}

.rmsc .dropdown-heading {
  font-size: 13px;
  height: 26px !important;
  color: #3e3e3e;
}

.rmsc .dropdown-container {
  border: 1px solid #eaeaea !important;
}

.dropdown-content {
  font-size: 13px;
  accent-color: #f04e29;
  padding-bottom: 30px !important;
}

.hideCheckbox .dropdown-content input[type=checkbox] {
  display: none !important;
}

.deleteIconInEditInvestorInformationMultiSelect .dropdown-search-clear-icon {
  display: none;
}

/* multiselectDropdown in EditList */
.edit-list-multi-select .dropdown-content {
  position: relative !important;
  bottom: 342px;
  height: 0px;
  padding: 0px !important;
}

.edit-list-multi-select .dropdown-heading{
  max-width: 500px;
}

@media (max-width: 560px) {
  .edit-list-multi-select .dropdown-heading {
    max-width: none;
  }
}


/* animation for modal (popup) */
@keyframes anvil {
  0% {
    transform: translateY(-30px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}

.popup-content {
  animation-name: anvil;
  animation-duration: 0.3s;
}

.popup-content>div {
  margin: 0px 4px;
}


/* animation for box in ContactLabel */
@keyframes box-appear {
  0% {
    transform: translateY(-20px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    ;
  }
}

.box-display {
  animation-name: box-appear;
  animation-duration: 0.2s;
}



/* animation for userfeedback */
@keyframes userfeedbackbox-appear {
  0% {
    transform: translateX(200px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }

  6% {
    transform: translateX(0px);
    opacity: 1;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  }

  94% {
    transform: translateX(0px);
    opacity: 1;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  }

  100% {
    transform: translateX(200px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
}

.userfeedback-display {
  animation-name: userfeedbackbox-appear;
  animation-duration: 4500ms;
}












/* Scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bcbec0;
  border-radius: 16px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8c8c8c;
}